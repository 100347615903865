@import "colors.scss";

#contact-success-panel {
    z-index: 2000;
    height: 100%;
    
    .panel-background {
        background: $palette-primary;
    }

    .content {
        position: relative;
        z-index: 100;
        margin: 180px auto 0 auto;
    }

    h1 {
        top: auto;
        left: auto;
        position: relative;
        text-align: center;  
        margin: 0 auto;
        width: 86px;
    }

    h2 {
        text-align: center;        
        color: $palette-darkest;
        font-size: 50px;
        line-height: 1;
        letter-spacing: 0.2px;
    }

    .small-rule {
        width: 24px;
        border-bottom: 3px solid $palette-darkest;
        margin: 50px auto;
    }

    #hide-success {
        color: $palette-darkest;
        border: 2px solid $palette-darkest;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2.5px;
        border-radius: 2px;
        line-height: 50px;
        width: 200px;
        text-align: center;

        display: block;
        margin: 0 auto;

        &:hover {
            text-decoration: none;
            opacity: .9;
        }

        &:visited {
            color: $palette-darkest;
        }
    }

    @media screen and (max-width: 699px) {

        padding-left: 10px;
        padding-right: 10px;

        h1 {
            width: 101px;
        }

        h1 + .small-rule {
            margin: 23px auto 118px auto;
        }

        .content {
            margin-top: 29px;
        }

        h2 {
            font-size: 48px;
            letter-spacing: 0.1px;
        }

        h2 + .small-rule {
            margin: 118px auto 23px auto;
        }

        #hide-success {
            text-align: center;
           
        }

    }
}


  
  