@import "_colors.scss";
@import "_responsive.scss";

#contact-panel {
  z-index: 1000;
  color: $palette-gray-med;
  padding-left: 25px;
  padding-right: 25px;

  .panel-background {
    z-index: 0;
    background: $palette-gray-light;
  }

  .site-info, .content {
    z-index: 100;
    position: relative;
  }

  .site-info {            
    padding: 25px 0;
    z-index: 200;
    width: 100%;

    .main-logo {
      margin: 0 0 0 0;
    }
    .separator {
      padding: 0 16px 0 14px;
    }
    .contact-line {
      transition: color 0.5s;
      color: $palette-darkest;
      font-size: 16px;
      margin-left: $hero-column-margin;

      .phone {
      letter-spacing: 2px;
      }
    } 
  }

  .contact-line {
    color: $palette-gray-dark;       
    
    @media screen and (max-width: $screen-md) {

      .phone, .email {
        display: block;
        width: 100%;

        padding-top: 10px;
        padding-bottom: 15px;
      }

      .separator {
        display: none;
      }
    }
  }

  hr {
    border: 0;
    border-bottom:1px solid  #d0cbc3;
  }

  h2 {
    color: $palette-gray-dark;
    line-height: 2.1;
    margin: 0;
  }

  .content {
    margin: 0 auto; 
  }

  #contact-cancel {
    background: transparent;
    color: $palette-gray-med;
    //border: 1px solid $palette-gray-med;
    margin-right: 25px;
  }

  a {
    color: $palette-gray-dark;
    text-decoration: none;
    &:visited {
      color: $palette-gray-dark;
    }
  }

  #mc_embed_signup div.mce_inline_error {
    color: #6B0505 !important;
    background: $palette-gray-light !important;
    font-weight: normal !important;
    font-size: 20px;
  }

  #mce-error-response {
    font-size: 20px;
    color: #6B0505 !important;
  }

  @media screen and (min-width: $screen-md) {
    .content {        
      padding-top: 25px;

      h2 {
        letter-spacing: 0.1px;
        font-size: 18px;
      }

      #mc_embed_signup {
        margin-top: 70px;
      }
    }
  }

  @media screen and (max-width: ($screen-md - 1)) {

    .site-info {
      padding-left: 0;

      .main-logo {
        margin: 0;
      }

      .container {
        padding-left: 0;
      }

      .contact-line {
        text-align: left;
        margin-top: 25px;
        margin-left: 0;

        .phone, .email {
          padding-bottom: 5px;
        }
      }  
    }

    .content {
      padding-top: 0px;
    }

    hr, h2 {
      display: none;
    }

    label {
      font-size: 14px;
      letter-spacing: .2px;
    }

    input[type=text], 
    input[type=email] {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.1px;
    }

    //prevent zoom on ios
    select:focus,
    textarea {
      font-size: 20px;
      letter-spacing: 0.1px;
    }

    textarea {
      letter-spacing: 0.2px;
      line-height: 1.57;
      height: 166px;
      padding: 14px;
    }

    input[type=submit], .form-button {
      float: none;
      width: 100%;
      font-size: 14px;
      margin-bottom: 25px;
    }

    #mc_embed_signup {
      margin-top: 25px;
    }

   
  }
}


