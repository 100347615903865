@import "_reset.scss";
@import "_colors.scss";
@import "_typography.scss";
@import "_form.scss";
@import "_responsive.scss";

html {
  width: 100%;
}   

body {
  color: $color-text;
}

.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

img {
  width: 100%;
}

section {
  position: relative;
}

header {
  .contact-line {
    color: $palette-darkest;
    font-size: 16px;
    .phone {
      letter-spacing: 2px;
    }
  }

  a {
    color:  white;
    color: $palette-darkest;
    text-decoration: none;

    &:visited {
      color: $palette-darkest;
    }
  }
}

hr {
  opacity: .3;
  width: 100%;
  border-bottom: 2px solid white;
}

.main-logo {
  background-repeat: no-repeat;
  // background-size: contain;
  text-indent: -9999px;
  overflow: hidden;

  padding: 0;
  z-index: 100;
  position: relative;

  height: 19px;

  transition: background-image 0.5s;

  &.white {
    background-image: url(img/kinetic-logo-white.svg);
  }

  &.black {
    background-image: url(img/kinetic-logo-black.svg);
  }

  &.black-all {
    background-image: url(img/kinetic-logo-black-all.svg);
  }
}

video.hero { 
  position: absolute;
  top: 0;
  left: 0%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  // -ms-transform: translateX(-50%) translateY(-50%);
  // -moz-transform: translateX(-50%) translateY(-50%);
  // -webkit-transform: translateX(-50%) translateY(-50%);
  // transform: translateX(-50%) translateY(-50%);
  background-size: cover; 
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
 
  opacity: 0.39;
  mix-blend-mode: multiply;
  background-color: #261f12;
  // background: rgba(0, 0, 0, .5);
  z-index: 0;
}

.video-blend {
  position: absolute;

  left: 0;
  bottom: 0;
  right: 0;
  height: 110px;

  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(4, 4, 4, 0), #040404);
}


.background-video.small {
  @media(max-width: ($screen-md - 1) ) {
  display: block;
  }
}

.arrow {
  display: inline-block;
  width: 27px;
  height: 12px;
  background-size: 100%;

  &.black {
  background-image: url('img/arrow-black.svg')
  }

  &.yellow {
  background-image: url('img/arrow-yellow.svg')

  }
}

.learn-more {
  margin: .5px 0;
  color: $palette-primary;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  line-height: 2.5;
  letter-spacing: 1.7px;

  &:visited {
  color: $palette-primary;
  }

  &:hover {
  color: $palette-primary;
  }
}

section.hero {

  .hero-text {
    p {
      font-size: 16px;
      margin: 1.5em 0;
    }

    
    a.show-contact {
      color: $palette-primary-dark;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2.5px;
      text-decoration: none;
    
      &:hover {
        color: $palette-primary-dark;      
      }
      &:visited {
      color: $palette-primary-dark;        
      }
    }
  }
}

section.what-we-do {
  h4 {
    color: $palette-primary-dark;
  }
}

section.clients {

  a {
    text-decoration: none;
  }

  h4, p {
    color: white;    
  }

  .client-headline {

    h4 {
      font-size: 14px;
      font-weight: bold;
    }

    .client-logo {
      height: 50px;
      width: auto;
    }

    p {
      font-size:18px;
    }  
  }
  .client-blocks {


    .client {
      position: relative;
      min-height: 210px;
      background-color: $palette-black;  
      height: 100%; 
     

      p {
        font-weight: bold;
        margin: 0 0 5px 0;
      }

      .client-logo {
        height: 25px;
        position: absolute;
        bottom: 25px;
        left: 25px;
        width: auto;
      }

      &.fankey {
        .client-logo {
          height: 20px;
        }
      }

      &.ad {
        .client-logo {
          height: 10px;
        }
      }
    }
  }
}

section.quotes {
  // background-color: #f1f1f1;
  background: $palette-primary;

  .attribution {
  font-size: 16px;
  color: $palette-darkest;

  .title {
    font-weight: bold;
  }

  .separator {
    margin: 0 10px;
  }
  }

  p {
  font-family: 'kinetic-brand';
  font-size: 26px;
  }

}

section.founders {
  overflow: hidden;

  h2, h3, h4, p {
    color: white;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    font-family: "brother-1816", sans-serif;
  }

  .copy-block {
    font-family: 'kinetic-brand', serif;
    font-size: 20px;
  }

  hr {
   margin: 10px 0 50px 0;    
  }

  .founders {
    display:none;
    position: absolute;
    right: 0; 

    @media screen and (max-width: $screen-md) {
      padding-left: 0;
      position: relative;
    }

    a {
      color: white;
      text-decoration: none;
      &:visited {
      color: white;
      }
    }
  
    .person {
      margin-top: 45px;
    }

    .profile-pic {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      display: block;
      margin-top: 8px;
    }

    h4 {
      font-size: 16px;
      margin: 0;
    }

    p {
      font-size: 16px;
      margin: 2px 0;
    }

    .psuedo-link {
      color: $palette-primary;
      text-decoration: none;
      font-size: 16px;
    }
  }
}

section.services {
  background-color: $palette-black;
  color: white;


  h3 {
  color: white;
  }

  ul {
  margin: 0;
  padding: 0;
  }

  ul li {
  list-style-type: none;

  font-size: 14px;
  margin: 0;
  margin-bottom: 14px;
  
  }
}

section.cta {
  background: $palette-primary;

  .container {
  margin: 0 auto;
  }

  h2, p {
   text-align: center;
  }

  a {
  color: $palette-darkest;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.5px;
  }
}

footer {
  background-color: #f0efee;

  .main-logo {
    height: 28px;
  }

  label, span {
    color:  $palette-gray-med;
    font-size: 15px;
  }

  p {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .address-block {
    > p {
      white-space: nowrap;
    }
  }

  label {
    display: inline;
    font-weight: bold;
  }

  .separator {
    margin: 0 10px;
  }

  a {
  color:  $palette-gray-med;
  }

  .copyright {
    text-align: center;
    display: block;
    font-size: 10px;
  }
}


.hidden {
  display: none;
}

.panel {
  width: 100%;
}

.panel-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
}


@import "_layout-sm.scss";
@import "_layout-lg.scss";
@import "_layout-md.scss";
